<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <el-form :model="form" :rules="rules" ref="form" :inline="true" :label-position="$config.labelPosition"
                 :label-width="$config.labelWidth">
          <div class="form-label-base">
            基本信息
          </div>
          <el-form-item label="单位名称" prop="partnerName">
            <el-input v-model="form.partnerName" placeholder="请输入" maxlength="25"></el-input>
          </el-form-item>
          <!--<el-form-item label="联系人" prop="companyContact">-->
            <!--<el-input v-model="form.companyContact" placeholder="请输入" maxlength="25"></el-input>-->
          <!--</el-form-item>-->
          <!-- <el-form-item label="手机号码" prop="companyContactPhone">
            <el-input v-model="form.companyContactPhone" placeholder="请输入" maxlength="11"></el-input>
          </el-form-item> -->
          <el-form-item label="客户地址">
            <el-input v-model="form.companyAddress" placeholder="请输入" maxlength="32"></el-input>
          </el-form-item>
          <el-form-item label="单位简写">
            <el-input v-model="form.partnerShorthand" placeholder="请输入" maxlength="16"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码">
            <el-input v-model="form.unifiedSocialCreditCode" placeholder="请输入" maxlength="32"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="form.partnerRemark" placeholder="请输入" maxlength="64"></el-input>
          </el-form-item>


          <!--<span class="form-line"></span>-->
          <!--<el-form-item label="联系人数量" prop="companyContactNum" class="clearMr">-->
            <!--<el-input v-model="form.companyContactNum"-->
                      <!--@keyup.enter.native="addAisle"-->
                      <!--maxlength="2"-->
                      <!--type="Number"></el-input>-->
            <!--<el-button type="primary" @click="addAisle" style="position: absolute;right: -80px">确 认</el-button>-->
          <!--</el-form-item>-->
          <div class="aisle"
               v-loading="aisleLoading"
               element-loading-text='联系人正在生成...'>
            <div v-for="(item, idx) in form.steelPartnerContactInfoVOList" :key="idx">
              <div class="aisle-form">
                <i class="el-icon-close curp"
                   style="position: absolute; top: 19px; right: 20px; color: #999999; font-size: 18px"
                   @click="closeItm(idx)"></i>
                <span class="aisle-title">联系人{{idx+1}}</span>
                <el-form-item label="客户联系人"
                              :prop="'steelPartnerContactInfoVOList['+idx+'].contactName'"
                              :rules="[{min: 2, max: 25, required: true, message: '请输入2-25位客户联系人姓名', trigger: 'blur'}]">
                  <!--, {validator: $validator.isContainBlank}-->
                  <el-input v-model="item.contactName" maxlength="25"></el-input>
                </el-form-item>

                <el-form-item label="电话号码"
                              :prop="'steelPartnerContactInfoVOList['+idx+'].contactPhone'"
                              :rules="[{max: 13, required: true, message: '请输入电话号码', trigger: 'blur'}]">
                  <el-input v-model="item.contactPhone" maxlength="13"></el-input>
                </el-form-item>
                <el-form-item label="负责人"
                              :prop="'steelPartnerContactInfoVOList['+idx+'].contactResponsible'">
                  <el-input v-model="item.contactResponsible"
                            maxlength="25"></el-input>
                </el-form-item>
                <el-form-item label="备注"
                              :prop="'steelPartnerContactInfoVOList['+idx+'].remark'">
                  <el-input v-model="item.remark"
                            maxlength="64"></el-input>
                </el-form-item>
              </div>
            </div>

            <div style="text-align: center">
              <el-button type="primary" icon="el-icon-plus" plain @click="addAisle">新增联系人</el-button>
            </div>
          </div>

          <div class="form-label-base">
            财务信息
          </div>
          <el-form-item label="开户银行">
            <el-input v-model="form.accBank" placeholder="请输入" maxlength="32"></el-input>
          </el-form-item>
          <el-form-item label="户名">
            <el-input v-model="form.accName" placeholder="请输入" maxlength="32"></el-input>
          </el-form-item>
          <el-form-item label="账号">
            <el-input v-model="form.accNumber" placeholder="请输入" maxlength="32"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button type="primary" @click="submitForm()" :loading="submitLoading">确 认</el-button>
        <el-button style="margin-left: 30px" @click="$router.push('/operation/operations-management')">返 回</el-button>
      </div>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
  const aisle = {
    contactName: null,
    contactPhone: null,
    contactResponsible: null,
    remark: null
  }
  export default {
    data () {
      const validateContactPhone = (rule, value, callback) => {
        if (!value || !(/^[1][0-9][0-9]{9}$/.test(value))) {
          callback(new Error('请输入11位手机号码'))
        } else {
          callback()
        }
      }
      return {
        ruleContactPhone: validateContactPhone,
        nav: [{name: '往来单位管理', path: ''}, {name: '往来单位维护', path: '/operation/operations-management'}, {
          name: '编辑',
          path: ''
        }],
        submitLoading: false,
        form: {
          partnerName: null,            //往来单位名称
          companyContact: null,         //联系人
          companyContactPhone: null,    //联系电话
          companyAddress: null,         //客户地址
          partnerShorthand: null,       //公司简写
          unifiedSocialCreditCode: null, //统一社会信用代码
          partnerRemark: null,          //备注
          accBank: null,                //开户行
          accName: null,                //户名
          accNumber: null,              //账号

          companyContactNum: 1,
          steelPartnerContactInfoVOList: [{
            contactName: null,
            contactPhone: null,
            contactResponsible: null,
            remark: null
          }]
        },
        aisleLoading: false,

        rules: {
          partnerName: [{
            max: 25,
            required: true,
            message: '请输入往来单位名称',
            trigger: 'blur'
          }, {validator: this.$validator.isContainBlank}],
          companyContact: [{
            min: 2,
            max: 25,
            required: true,
            message: '请输入2-25位联系人',
            trigger: 'blur'
          }, {validator: this.$validator.isContainBlank}],
          companyContactPhone: [{required: true, trigger: 'blur', validator: validateContactPhone}],
          companyAddress: [{
            max: 32,
            required: true,
            message: '请输入客户地址',
            trigger: 'blur'
          }, {validator: this.$validator.isContainBlank}],
        }
      }
    },
    methods: {
      closeItm (i) {
        if (this.form.steelPartnerContactInfoVOList.length===1) {
          this.$message.warning('必须保留一个联系人')
          return
        }
        this.form.steelPartnerContactInfoVOList.splice(i, 1)
        this.form.companyContactNum = this.form.steelPartnerContactInfoVOList.length
      },
      addAisle () {
        if (this.form.companyContactNum === 20) {
          this.$message.warning('最多只可添加20名位联系人')
          return
        }
        this.form.companyContactNum++
        this.aisleLoading = true
        setTimeout(() => {
          this.aisleLoading = false
          // if (Number(this.form.companyContactNum) < Number(this.form.steelPartnerContactInfoVOList.length)) {
          //   this.form.steelPartnerContactInfoVOList =
          //     this.form.steelPartnerContactInfoVOList.slice(0, Number(this.form.companyContactNum))
          // } else {
          //   this.num =
          //     Number(this.form.companyContactNum) -
          //     Number(this.form.steelPartnerContactInfoVOList.length)
          //   for (let i = 0; i < this.num; i++) {
          this.form.steelPartnerContactInfoVOList.push(this.$_.cloneDeep(aisle))
          // }
          // }
        }, 350)
      },
      async addOperations () {
        this.form.companyContactNum = Number(this.form.companyContactNum)
        await this.$api.operationsUpdate({
          partnerInfoVO: this.form
        })
        this.$notify({
          title: '成功',
          message: '修改成功',
          type: 'success'
        })
        this.$router.push('/operation/operations-management')
      },
      submitForm () {
        this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            this.addOperations()
          } catch (e) {}
          this.submitLoading = false
        })
      },
    },
    async mounted () {
      this.form = await this.$api.operationsGet({
        partnerId: this.$route.query.partnerId,
      })
    },
  }
</script>

<style lang='sass' scoped>
  .content-main
    .form-line
      display: block
      width: 100%

    .clearMr
      position: relative

    .aisle
      width: calc(100% - 64px)
      padding: 0 24px 24px 24px
      margin-bottom: 24px
      margin-left: 64px

      &-title
        display: block
        color: #999
        margin-bottom: 24px

      &-form
        width: 100%
        display: inline-block
        background: rgba(250, 250, 250, 1)
        box-shadow: 0 2px 5px 0 rgba(8, 88, 169, 0.1)
        border-radius: 3px
        padding: 20px
        margin-bottom: 23px
        position: relative

        /deep/ .el-form-item
          width: 48%

        /deep/ .el-form-item__label
          display: inline-block
          width: 100px
          text-align: right

        /deep/ .el-select, /deep/ .el-input
          width: 200px !important

        /deep/ .el-input__inner
          background-color: transparent
</style>
